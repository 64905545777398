/** @jsx jsx */
import React, {useEffect, useRef, useState} from "react"
import { jsx, Link as TLink, Heading } from "theme-ui"
import { Link } from "gatsby"
import Img from "gatsby-image"
import ColorThief from "colorthief/dist/color-thief"

const colorThief = new ColorThief();
const px = [`64px`, `32px`, `16px`, `8px`, `4px`]

const Card = ({ item, inGrid = false }) => {
    const [colorThiefValue, setColorThiefValue] = useState(false)
    const [overlay, setOverlay] = useState('0, 0, 0')
    const [shadow, setShadow] = useState(px.map((v) => `rgba(0, 0, 0, 0.15) 0px ${v} ${v} 0px`))
    const h = item.data.images[0].image.localFile.childImageSharp.fluid.presentationHeight
    const count = Math.floor(h / 50)
    const imgRef = useRef()

    useEffect(() => {
        if(colorThiefValue){
            const shadow = `${colorThiefValue[0]}, ${colorThiefValue[1]}, ${colorThiefValue[2]}`
            const shadowArray = px.map((v) => `rgba(${shadow}, 0.15) 0px ${v} ${v} 0px`)
            setOverlay(shadow)
            setShadow(shadowArray)
        }
    }, [colorThiefValue])

    let conditionalStyles = {}

    if (inGrid) {
        conditionalStyles = {
            height: 0,
            paddingBottom: `${100 / item.data.images[0].image.localFile.childImageSharp.fluid.aspectRatio}%`,
            gridRowEnd: `span ${count}`,
            mb: 4,
        }
    }

    return (
        <TLink
            as={Link}
            aria-label={`Visit ${item.data.title.text} project page`}
            sx={{
                outline: `none`,
                "&:focus": {
                    boxShadow: `${shadow.join(`, `)}, rgba(${overlay}, 0.5) 0px 0px 0px 10px`,
                },
                "&:hover, &:focus": {
                    "[data-name='card-overlay']": {
                        opacity: 1,
                    },
                },
                boxShadow: shadow.join(`, `),
                position: `relative`,
                ...conditionalStyles,
            }}
            to={`/${item.uid}`}
        >
            <div
                sx={{
                    zIndex: 20,
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    opacity: 0,
                    transition: `all 0.3s ease-in-out`,
                    color: `white`,
                    backgroundColor: `rgba(${overlay}, 0.9)`,
                    position: `absolute`,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}
                data-name="card-overlay"
            >
                <Heading variant="styles.h2" sx={{ my: 0, textShadow: `rgba(0, 0, 0, 0.2) 0px 2px 12px`, color: `white` }}>
                    {item.data.title.text}
                </Heading>
            </div>
            <Img fluid={item.data.images[0].image.localFile.childImageSharp.fluid} ref={imgRef} onLoad={() => {
                // Set colorthief when image is loaded
                if(imgRef.current && imgRef.current.imageRef && imgRef.current.imageRef.current){
                    const img = imgRef.current.imageRef.current
                    setColorThiefValue(colorThief.getColor(img))
                }
            }} />
        </TLink>
    )
}

export default Card
/** @jsx jsx */
import { jsx, Container } from "theme-ui"
import { animated, useSpring, config } from "react-spring"
import Img from "gatsby-image"
import Layout from "./Layout"
import HeaderProject from "./HeaderProject"
import ProjectPagination from "./ProjectPagination"
import SEO from "./Seo"

const Project = ({pageContext}) => {
    const {data, next, prev} = pageContext
    const d = new Date(data.first_publication_date)
    const date = `${d.getDate()}.${d.getMonth() + 1 }.${d.getFullYear()}`
    const imageFade = useSpring({ config: config.slow, delay: 800, from: { opacity: 0 }, to: { opacity: 1 } })

    return (
        <Layout>
            <SEO
                title={data.data.title.text}
                pathname={data.uid}
                image={data.data.images[0].image.localFile.childImageSharp.fluid.src}
            />
            <HeaderProject title={data.data.title.text} description={data.data.content.text} date={date} />
            <Container sx={{ mt: [`-6rem`, `-6rem`, `-8rem`] }}>
                {data.data.images.map((image, index) => (
                    <animated.div key={`image-${index}`} style={imageFade}>
                        <Img fluid={image.image.localFile.childImageSharp.fluid} sx={{ mb: [4, 4, 5], boxShadow: `xl` }} />
                    </animated.div>
                ))}
                <ProjectPagination prev={prev} next={next} />
            </Container>
        </Layout>
    )
}

export default Project
/** @jsx jsx */
import React from "react"
import {Container, Flex, Heading, jsx, Styled} from "theme-ui"
import {animated, config, useSpring} from "react-spring"
import {graphql, Link, useStaticQuery} from "gatsby"
import HeaderBackground from "./HeaderBackground"
import Img from "gatsby-image"

const LeftArrow = () => (
    <svg width="20" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.832 17.642L.345 9.864a1.256 1.256 0 0 1 0-1.728L7.832.358a1.146 1.146 0 0 1 1.663 0c.46.477.46 1.251 0 1.728L4.017 7.778H20v2.444H4.017l5.478 5.692c.46.477.46 1.25 0 1.728a1.146 1.146 0 0 1-1.663 0z"
            fill="currentColor"
        />
    </svg>
)

const HeaderProject = ({title, description = ``, date}) => {
    const data = useStaticQuery(graphql`
    query {
      allPrismicSiteConfig {
        edges {
          node {
            id
            data {
              blog_title
              logo {
                localFile {
                    childImageSharp {
                      fixed(width: 40, height: 40, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                }
              }
            }
          }
        }
      }
    }
  `)

    const siteData = data.allPrismicSiteConfig.edges[0].node.data

    const titleProps = useSpring({
        config: config.slow,
        from: {opacity: 0, transform: `translate3d(0, -30px, 0)`},
        to: {opacity: 1, transform: `translate3d(0, 0, 0)`},
    })
    const backButtonProps = useSpring({
        config: config.slow,
        from: {opacity: 0, transform: `translate3d(-30px, 0, 0)`},
        to: {opacity: 1, transform: `translate3d(0, 0, 0)`},
    })
    const infoProps = useSpring({config: config.slow, delay: 500, from: {opacity: 0}, to: {opacity: 1}})

    return (
        <Flex as="header" variant="layout.header">
            <HeaderBackground/>
            <Container sx={{textAlign: `center`, my: 4, zIndex: 10}}>
                <animated.div style={backButtonProps}>
                    <Link
                        to="/"
                        aria-label={`${siteData.blog_title} - Retour`}
                        sx={{
                            display: `flex`,
                            alignItems: `center`,
                            color: `text`,
                            textDecoration: `none`,
                            svg: {
                                transition: `transform 0.25s cubic-bezier(0.455, 0.03, 0.515, 0.955)`,
                            },
                            "&:hover, &:focus": {svg: {transform: `translateX(-6px)`}},
                        }}
                    >
                        <LeftArrow/>
                        <div
                            sx={{
                                overflow: `hidden`,
                                borderRadius: `full`,
                                width: `40px`,
                                height: `40px`,
                                display: `inline-block`,
                                boxShadow: `md`,
                                mx: 2,
                            }}
                        >
                            <Img fixed={siteData.logo.localFile.childImageSharp.fixed} alt="logo" />
                        </div>
                        <span sx={{fontWeight: `medium`}}>{siteData.blog_title}</span>
                    </Link>
                </animated.div>
                <div sx={{mt: 4, mb: [6, 6, 7]}}>
                    <animated.div style={titleProps}>
                        <Heading as="h1" variant="styles.h1">
                            {title}
                        </Heading>
                    </animated.div>
                    <animated.div style={infoProps}>
                        <Styled.p sx={{mb: 0, mt: 4}}>{date}</Styled.p>
                        {description && (
                            <div sx={{maxWidth: `900px`, mx: `auto`, mt: 5, p: {textAlign: `left`}}}>
                                <p>{description}</p>
                            </div>
                        )}
                    </animated.div>
                </div>
            </Container>
        </Flex>
    )
}

export default HeaderProject